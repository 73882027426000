import css from "../css/layout/compact.module.css";

import React from "react";
import Router from "next/router";
import { useAuth } from "../components/common/Auth";
import LayoutCompact from "../components/LayoutCompact";
import LoginForm from "../components/auth/LoginForm";
import { getUserDashboardRoute } from "../service/auth";

const Login = () => {
    const { isAuthenticated, profile } = useAuth();

    if (isAuthenticated) {
        Router.push(getUserDashboardRoute(profile));
        return <></>;
    }

    const handleSuccess = (profile) => {
        Router.push(getUserDashboardRoute(profile));
    };

    return (
        <LayoutCompact className={css.logoCentered}>
            <h3 className={css.header}>Login to account</h3>
            <div className={css.headerText} />

            <div className={css.form}>
                <LoginForm onSuccess={handleSuccess} />
            </div>
        </LayoutCompact>
    );
};

export default Login;
