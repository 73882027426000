import css from "../css/layout/compact.module.css";

import React from "react";
import Logo from "./header/Logo";
import PropTypes from "prop-types";

const LayoutCompact = (props) => {
    const logoClassName = props.className ? props.className : css.logo;

    return (
        <div className={css.wrapper}>
            <div className={css.inner}>
                <div className={logoClassName}>
                    <Logo size="sm" />
                </div>
                <div>{props.children}</div>
            </div>
        </div>
    );
};

LayoutCompact.propTypes = {
    className: PropTypes.string,
};

export default LayoutCompact;
