import css from "../../css/auth/login-form.module.css";
import formCss from "../../css/form/login.module.css";
import checkboxCss from "../../css/auth/checkbox.module.css";

import { ErrorMessage, Field, Form, Formik } from "formik";
import AccountIcon from "mdi-react/AccountIcon";
import LockIcon from "mdi-react/LockIcon";

import React, { useState } from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import * as Yup from "yup";
import { fetchLogin, fetchReactivate } from "../../service/auth";
import { useAuth } from "../common/Auth";

const ValidationSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email")
        .label("Email")
        .required("Required")
        .max(200),
    password: Yup.string().required("Required").label("Password").max(200),
});

const LoginForm = ({ onSuccess }) => {
    const { login } = useAuth();
    const [isDeactivated, setIsDeactivated] = useState(false);
    const [error, setError] = useState(null);

    const handleReactivate = async (
        email,
        password,
        setSubmitting,
        setErrors
    ) => {
        try {
            const res = await fetchReactivate(email, password);

            login(res.data.access_token, res.data.profile);

            onSuccess(res.data.profile);
        } catch (err) {
            if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.error
            ) {
                const response = err.response.data;
                const responseError = response.error;
                let errors = {};

                for (let field in responseError) {
                    errors[field] = Object.values(responseError[field]);
                }

                setErrors(errors);

                if (errors.global) {
                    setError(errors.global);
                }
            }
        }

        setSubmitting(false);
    };

    return (
        <Formik
            initialValues={{
                email: "",
                password: "",
            }}
            validationSchema={ValidationSchema}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                try {
                    const res = await fetchLogin(values.email, values.password);

                    login(res.data.access_token, res.data.profile);

                    onSuccess(res.data.profile);
                } catch (err) {
                    if (
                        err &&
                        err.response &&
                        err.response.data &&
                        err.response.data.error
                    ) {
                        const response = err.response.data;
                        const responseError = response.error;
                        let errors = {};

                        for (let field in responseError) {
                            errors[field] = Object.values(responseError[field]);
                        }

                        setErrors(errors);

                        if (errors.global) {
                            setError(errors.global);
                            setIsDeactivated(response.is_deactivated || false);
                        }
                    }
                }

                setSubmitting(false);
            }}
        >
            {(props) => (
                <Form style={{ textAlign: "left" }}>
                    <div className={formCss.group}>
                        <div className={formCss.inputGroup}>
                            <AccountIcon />
                            <Field
                                type="email"
                                inputMode="email"
                                autoComplete="username"
                                name="email"
                                placeholder="Your Email"
                                className={formCss.input}
                            />
                        </div>
                        <ErrorMessage
                            name="email"
                            component="div"
                            className={formCss.error}
                        />
                    </div>

                    <div className={formCss.group}>
                        <div className={formCss.inputGroup}>
                            <LockIcon />
                            <Field
                                type="password"
                                inputMode="text"
                                autoComplete="current-password"
                                name="password"
                                placeholder="Your Password"
                                className={formCss.input}
                            />
                        </div>
                        <ErrorMessage
                            name="password"
                            component="div"
                            className={formCss.error}
                        />
                        {error && (
                            <div className={formCss.error}>
                                {error}
                                <br />
                                {isDeactivated && (
                                    <a
                                        href="#"
                                        onClick={() =>
                                            handleReactivate(
                                                props.values["email"],
                                                props.values["password"],
                                                props.setSubmitting,
                                                props.setErrors
                                            )
                                        }
                                        className={formCss.reactivateBtn}
                                    >
                                        Re-activate account
                                    </a>
                                )}
                            </div>
                        )}
                    </div>

                    <div className={formCss.row}>
                        <div className={formCss.group}>
                            <label className={checkboxCss.checkbox}>
                                Remember me on this computer
                                <input
                                    name="remember_me"
                                    type="checkbox"
                                    value="1"
                                    checked={props.values["remember_me"]}
                                />
                                <span className={checkboxCss.checkmark} />
                            </label>
                        </div>
                        <div>
                            <Link
                                href="/restore-password"
                                as="/myaccount/forgot_password"
                                className={css.forgotLink}
                            >
                                Forgot your Password?
                            </Link>
                        </div>
                    </div>

                    <div className={css.line} />

                    <button
                        type="submit"
                        className={formCss.submit}
                        disabled={props.isSubmitting}
                        onClick={props.handleSubmit}
                    >
                        Sign In
                    </button>
                </Form>
            )}
        </Formik>
    );
};

LoginForm.propTypes = {
    onSuccess: PropTypes.func,
};

export default LoginForm;
